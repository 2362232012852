<template>
  <div :style="background">
    <!-- LinkedIn Campaign Image -->
    <img height="1" width="1" style="display:none;" alt="" src=" px.ads.linkedin.com/collect/?p...onId=6454521&fmt=gif" />

    <CContainer class="min-vh-100">
      <CRow class="w-100 m-0">
        <CCol xl="12" lg="12" md="12" class="registration">
          <CCard class="mb-0 wizard">
            <CCardHeader class="pb-0">
              <CRow>
                <CCol cols="8" xl="8" class="pt-0 pb-0 text-left">
                  <div class="d-flex align-items-center">
                    <div v-if="environmentVariables.environment_id_external" class="mr-2">
                      <img v-if="environmentVariables.environment_tag === 'harryhr'" src="img/brand/harry_hr_logo_23_06_2022.png"/>
                      <img v-if="environmentVariables.environment_tag === 'employalty'" src="img/brand/employalty_logo.png"/>
                    </div>
                    <h1 class="m-0">{{$t('registration.Welcome_at')}} {{environmentVariables.environment_name}} 👋🏽</h1>
                  </div>
                </CCol>
                <CCol cols="4" xl="4" class="pt-0 pb-0 text-right">
                  <div class="locale-changer">
                    <select v-model="$i18n.locale" @input="updateLanguage()" class="pr-2 pl-2">
                      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.code">
                        {{ lang.flag }}
                      </option>
                    </select>
                  </div>
                </CCol>  
              </CRow>
            </CCardHeader>
            <CCardBody class="p-0">
              <!-- <CRow class="w-100 m-0">
                <CCol md="12" lg="12" class="pt-0 pb-0">
                  <p class="m-0">{{ $t('registration.introduction') }}</p>
                </CCol>
              </CRow> -->

              <CRow class="w-100 m-0">
                <CCol md="12" lg="12" class="p-0">
                  <CRow class="w-100 m-0">
                    <CCol xl="6" lg="6" md="6" class="p-0">
                      <CRow class="w-100 m-0">
                        <CCol md="12" lg="12">
                          <h2 class="subheader">{{ $t('registration.your_account') }}</h2>
                        </CCol>
                      </CRow>

                      <CRow class="w-100 m-0">
                        <CCol md="6" lg="6" class="pt-0">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.admin_name') }}</span>
                            <!-- <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.admin_name_help')}"/> -->
                          </label>                          
                          <CInput type="text" class="mb-0" v-model="companyData.admin_name" required was-validated/>
                        </CCol>
                        <CCol md="6" lg="6" class="pt-0">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.admin_email') }}</span>
                            <!-- <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.admin_email_help')}"/> -->
                          </label>                          
                          <CInput type="email" class="mb-0" v-model="companyData.admin_email" required was-validated/>
                        </CCol>              
                        <CCol md="12" lg="12" class="pt-0">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.admin_role') }}</span>
                            <!-- <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.admin_role_help')}"/> -->
                          </label>                            
                          <CSelect :value.sync="companyData.admin_role" :options="roles" :placeholder="$t('registration.admin_select_role')" required was-validated/>
                        </CCol>                       
                      </CRow>
                      
                      <CRow class="w-100 m-0">
                        <CCol md="12" lg="12">
                          <h2 class="subheader">{{ $t('registration.organization_details') }}</h2>
                        </CCol>
                      </CRow>

                      <CRow class="w-100 m-0">
                        <CCol md="12" lg="12" class="pt-0">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.enter_company_name') }}</span>
                            <!-- <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.company_name_help')}"/> -->
                          </label>
                          <CInput type="text" class="mb-0" v-model="companyData.company_name" required was-validated/>                        
                        </CCol>
                      </CRow>

                      <CRow class="w-100 m-0">
                        <CCol md="12" lg="12" class="pt-0">
                          <b-switch class="mb-0 mt-1 mt-xl-0" v-model="companyData.has_brand_name" size="is-small" @input="companyData.brand_name = companyData.company_name">{{ $t('registration.has_brand_name') }}</b-switch>
                        </CCol>
                        <CCol v-if="companyData.has_brand_name" md="12" lg="12" class="pt-0">                        
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.enter_brand_name') }}</span>
                            <!-- <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.brand_name_help')}"/> -->
                          </label>                          
                          <CInput type="text" class="mb-0" v-model="companyData.brand_name"/>                        
                        </CCol>
                      </CRow>                      

                      <CRow class="w-100 m-0">
                        <CCol md="6" lg="6" class="pt-0">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.upload_company_image') }}</span>
                            <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.company_image_help')}"/>
                          </label>
                          <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': companyImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setCompanyImage">
                            <label for="fileInput" slot="upload-label" class="mb-0" v-bind:class="{'mt-2': companyImageUploaded}">
                              <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{companyImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image') }}</span>
                            </label>
                          </image-uploader>
                        </CCol>
                        <CCol md="6" lg="6" class="pt-0">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.upload_header_image') }}</span>
                            <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.header_image_help')}"/>
                          </label>
                          <image-uploader id="fileInputHeader" class="image_upload_header" :preview="true" :className="['fileinput', { 'fileinput--loaded': headerImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setHeaderImage">
                            <label for="fileInputHeader" slot="upload-label" class="mb-0" v-bind:class="{'mt-2': headerImageUploaded}">
                              <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{headerImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image') }}</span>
                            </label>
                          </image-uploader>
                        </CCol>                                 
                      </CRow>

                      <CRow class="w-100 m-0">
                        <CCol md="6" lg="6" class="pt-0">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.primary_color') }}</span>
                            <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.primary_color_help')}"/>
                          </label>
                          <div class="color_palette">
                            <div v-for="(color, index) in primaryColorPalette" v-bind:key="index" class="color" v-bind:style="{ backgroundColor: color }" v-bind:class="{'selected' : companyImageUploaded && companyData.primary_color === color}" @click="customPrimary = false; companyData.primary_color = color;"></div>
                            <div class="color_picker">
                              <CInput id="primaryPicker" type="color" class="mb-0" v-model="companyData.primary_color" @input="customPrimary = true;"/>
                              <label for="primaryPicker" class="h-100 w-100 d-flex align-items-center justify-content-center m-0" v-bind:class="{'selected' : customPrimary}" v-bind:style="[customPrimary ? {backgroundColor: companyData.primary_color } : {backgroundColor: 'transparent'}]">
                                <i class="fas fa-eye-dropper"></i>
                              </label>
                            </div>
                          </div>

                        </CCol>
                        <CCol md="6" lg="6" class="pt-0">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.secondary_color') }}</span>
                            <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.secondary_color_help')}"/>
                          </label>                          
                          <div class="color_palette">
                            <div v-for="(color, index) in secondaryColorPalette" v-bind:key="index" class="color" v-bind:style="{ backgroundColor: color }" v-bind:class="{'selected' : companyImageUploaded && companyData.secondary_color === color}" @click="customSecondary = false; companyData.secondary_color = color;"></div>
                            <div class="color_picker">
                              <CInput id="secondaryPicker" type="color" class="mb-0" v-model="companyData.secondary_color" @input="customSecondary = true;"/>
                              <label for="secondaryPicker" class="h-100 w-100 d-flex align-items-center justify-content-center m-0" v-bind:class="{'selected' : customSecondary}" v-bind:style="[customSecondary ? {backgroundColor: companyData.secondary_color } : {backgroundColor: 'transparent'}]">
                                <i class="fas fa-eye-dropper"></i>
                              </label>
                            </div>                            
                          </div>                          
                        </CCol>              
                      </CRow>

                      <!-- <CRow class="w-100 m-0">
                        <CCol md="12" lg="12" v-bind:class="{'pb-0' : companyData.has_license_code}">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.license_code_available') }}</span>
                            <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.license_code_help')}"/>
                          </label>                          
                          <b-switch class="mb-0 mt-1" v-model="companyData.has_license_code" @input="companyData.license_code = null" size="is-small">{{ $t('registration.has_license_code') }}</b-switch>
                        </CCol>
                        <CCol v-if="companyData.has_license_code" md="12" lg="12">
                          <label class="d-flex align-items-center">
                            <span>{{ $t('registration.enter_license_code') }}</span>
                            <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{content: $t('registration.enter_license_code_help')}"/>
                          </label>                           
                          <CInput type="text" class="mb-0" v-model="companyData.license_code"/>
                        </CCol>
                      </CRow> -->

                      <CRow class="w-100 m-0">
                        <CCol md="12" lg="12">
                          <CButton color="primary" class="get_started" @click="createCompany(companyData);">
                            <span>{{$t('registration.get_started_with')}} {{environmentVariables.environment_name}}<i class="fas fa-chevron-right ml-1"/></span>
                          </CButton>
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol xl="6" lg="6" md="6">
                      <div class="text-center mb-2">
                        <span>{{$t('registration.your_' + environmentVariables.environment_tag)}}</span>
                      </div>
                      <appPreview :ref="'appPreview'" :environmentName="environmentVariables.environment_name" :environmentTag="environmentVariables.environment_tag" :previewData="companyData" previewMode="connect_social_wall" :previewContentData="null"/>
                    </CCol>
                  </CRow>                                                              
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CRow class="w-100 m-0">
                <CCol md="12" lg="12" class="p-0 text-center disclaimer">
                  <span v-if="environmentVariables.environment_tag === 'harryhr'">{{ $t('registration.disclaimer') }} <a href="https://harryhr.com/terms/" target="_blank">{{ $t('terms_of_service') }}</a> {{ $t('and') }} <a href="https://harryhr.com/policy/" target="_blank">{{ $t('privacy_policy') }}</a></span>
                  <span v-if="environmentVariables.environment_tag === 'employalty'">{{ $t('registration.disclaimer') }} <a href="https://employalty.app/over/" target="_blank">{{ $t('terms_of_service') }}</a> {{ $t('and') }} <a href="https://employalty.app/over/" target="_blank">{{ $t('privacy_policy') }}</a></span>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>  
</template>

<script>
import axios from 'axios';
import appPreview from '@/components/common/appPreview.vue';
import ImageUploader from 'vue-image-upload-resize';
import { prominent } from 'color.js'
import invalidEmailDomains from '@/assets/js/invalidEmailDomains';

export default {
  name: 'Registration',
  components: {
    appPreview,
    ImageUploader
  },
  data(){
    return {
      langs: [
        {code: 'en-EN', flag: 'English'},
        {code: 'nl-NL', flag: 'Nederlands'},
        {code: 'de-DE', flag: 'Deutsch'},
        {code: 'es-ES', flag: 'Español'},
        {code: 'fr-FR', flag: 'Français'},
        {code: 'zh-CN', flag: '中国人'},
        {code: 'pl-PL', flag: 'Polska'},
        {code: 'cs-CZ', flag: 'Česky'},
      ],      
      blockedEmailDomains: invalidEmailDomains,
      cdnBaseUrl: null,  
      copyrightText: null,     
      background : { 
        backgroundImage: null,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      },
      primaryColorPalette: ['#006859'],
      secondaryColorPalette: ['#B7B7B7'],
      customPrimary: false,
      customSecondary: false,
      companyData: {
        company_name: null,
        has_brand_name: false,
        brand_name: null,
        header_image: null,
        company_image: null,
        primary_color: '#006859',
        secondary_color: '#B7B7B7',
        admin_name: null,
        admin_email: null,
        admin_role: null,
        has_license_code: false,
        license_code: null
      },
      companyImageUploaded: false,
      headerImageUploaded: false,
      colorSettings: {
        amount: 5,
        format: 'hex',
        group: 20,
        sample: 20,
      },
      roles: [
        "Manager",
        "Human Resource Professional",
        "C-Level/VP",
        "Employee (not a Manager)"
      ],
      background : {
        backgroundImage: "linear-gradient(45deg, rgba(0, 104, 89, 0.9), rgba(48, 146, 139, 0.95)), url('./img/login/harry_hr_pattern.png')",
        backgroundRepeat: 'repeat',
      },
      maxYiqValue: 200,
      environmentVariables: {
        company_id_external: null,
        environment_id_external: null
      }      
    }
  },
  methods: {
    updateLanguage() {
      setTimeout(function(){
        localStorage.setItem('userPlatformLanguage', this.$i18n.locale);
        this.$bus.$emit('language_changed');
      }.bind(this), 100);
    },    
    checkColor(color) {
      // Remove the # from the color
      color = color.replace("#", "");
      // Calculate RGB values
      let r = parseInt(color.substr(0, 2), 16);
      let g = parseInt(color.substr(2, 2), 16);
      let b = parseInt(color.substr(4, 2), 16);
      // Calculate YIQ value
      let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      // Return true if YIQ value is higher than maxYiqValue      
      return yiq >= this.maxYiqValue;
    },
    createCompany(companyData) {
      let params = {};
      params = companyData;
      if(params.brand_name === null || params.brand_name === '') params.brand_name = params.company_name;
    
      // Validate the company
      let companyValidation = this.validateCompany(params);
      // If the validation is successful, create the company. If not, show a message
      if(companyValidation.validated === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/company', params)
        .then(res => {
          // Navigate to signed up page on Harry HR website
          window.open("https://harryhr.com/signed-up/","_self");          
        })
        .catch(err => {
          console.error(err);
        })
      } else {
        if(companyValidation.message === 'Company invalid') {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        } else if(companyValidation.message === 'No brand name') {
          this.$buefy.toast.open({ message: this.$t('registration.provide_brand_name'), type: 'is-danger', duration: 2000 });
        } else if(companyValidation.message === 'Invalid email domain') {
          this.$buefy.toast.open({ message: this.$t('registration.invalid_email_domain'), type: 'is-danger', duration: 2000 });
        } else if(companyValidation.message === 'Primary color too bright') {
          this.$buefy.toast.open({ message: this.$t('registration.invalid_primary_color_used'), type: 'is-danger', duration: 2000 });
        } else if(companyValidation.message === 'Secondary color too bright') {
          this.$buefy.toast.open({ message: this.$t('registration.invalid_secondary_color_used'), type: 'is-danger', duration: 2000 });
        }
      }
    },
    validateCompany(params) {
      let result = {};
      let admin_name = params.admin_name;
      let admin_email = params.admin_email;
      let admin_role = params.admin_role;
      let company_name = params.company_name;
      let has_brand_name = params.has_brand_name;
      let brand_name = params.brand_name;
      let company_image = params.company_image;
      let header_image = params.header_image;
      let primary_color = params.primary_color;
      let secondary_color = params.secondary_color;

      // Check if the base information for the company is available
      if(admin_name && admin_email && admin_role && company_name && company_image && header_image && primary_color && secondary_color) {
        // Check if the admin email is from a blocked email domain
        for(var i = 0; i< this.blockedEmailDomains.length; i++) {
          if(admin_email.indexOf(this.blockedEmailDomains[i]) != -1) {
            result = { validated: false, message: 'Invalid email domain' };
            return result;
          }
        }
        
        // Check if the primary color is too bright
        if(this.checkColor(primary_color) === true) {
          result = { validated: false, message: 'Primary color too bright' };
          return result;          
        }

        // Check if the secondary color is too bright
        if(this.checkColor(secondary_color) === true) {
          result = { validated: false, message: 'Secondary color too bright' };
          return result;          
        }        

        // Check if the brand name is empty or null
        if(has_brand_name === true && (brand_name === null || brand_name === '')) {
          result = { validated: false, message: 'Brand name missing' };
          return result;
        }
        
        result = { validated: true, message: 'Company valid' };
        return result;
      } else {
        result = { validated: false, message: 'Company invalid' };
        return result;
      }
    },
    getEnvironmentVariables() {
      // Get the hostname
      this.platformHostname = window.location.hostname;
      // Set the params
      let params = {};
      params.hostname = this.platformHostname;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/platform/environments/dashboard', params)
      .then(res => {
        this.environmentVariables = res.data.data;
        // Set the dashboard title
        document.title = this.environmentVariables.register_title;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.environmentVariables.primary_color);        
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', this.environmentVariables.primary_color);
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', this.environmentVariables.secondary_color);
        // Set the dashboard font
        document.documentElement.style.setProperty('--DashboardFontFamily', this.environmentVariables.font_family);
        // Set the background image
        this.background.backgroundImage =  "linear-gradient(45deg, rgba(" + this.environmentVariables.primary_color_rgb + ", 0.85), rgba(" + this.environmentVariables.primary_color_rgb + ", 0.9)), url('./img/login/harry_hr_pattern.png')",
        // Set the copyright text
        this.copyrightText = this.environmentVariables.copyright;
        
        // Set the favicon href and the placeholder for the email field
        if(this.environmentVariables.environment_tag ==='harryhr') {
          document.querySelector("link[rel~='icon']").href = 'img/brand/harry_hr_favicon_23_06_2022.png';
          this.emailFieldPlaceholder = "📩 Hi@Harryhr.com";
        } else if(this.environmentVariables.environment_tag ==='employalty') {
          document.querySelector("link[rel~='icon']").href = 'img/brand/employalty_favicon.png';
          this.emailFieldPlaceholder = "📩 Hi@Employalty.com";
        }        
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    setCompanyImage (output) {
      this.companyImageUploaded = true;
      this.companyData.company_image = output.dataUrl;

      prominent(output.dataUrl, { amount: this.colorSettings.amount, format: this.colorSettings.format, group: this.colorSettings.group, sample: this.colorSettings.sample}).then(color => {
        this.primaryColorPalette = color;

        for(var c = 0; c < this.primaryColorPalette.length; c++) {
          if(this.checkColor(this.primaryColorPalette[c]) === true) {            
            this.primaryColorPalette.splice(c, 1); 
            c--;
          }
        }

        this.companyData.primary_color = this.primaryColorPalette[0];
        this.customPrimary = false;
      });

      prominent(output.dataUrl, { amount: this.colorSettings.amount, format: this.colorSettings.format, group: this.colorSettings.group, sample: this.colorSettings.sample}).then(color => {
        this.secondaryColorPalette = color;

        for(var c = 0; c < this.secondaryColorPalette.length; c++) {
          if(this.checkColor(this.secondaryColorPalette[c]) === true) {            
            this.secondaryColorPalette.splice(c, 1);
            c--;
          }
        }

        this.companyData.secondary_color = this.secondaryColorPalette[0];
        this.customSecondary = false;
      });

      this.$refs.appPreview.reloadCompanyImage(this.companyData.company_image);  
    },
    setHeaderImage (output) {
      this.headerImageUploaded = true;
      this.companyData.header_image = output.dataUrl;
      this.$refs.appPreview.reloadHeaderImages(this.companyData.header_image);
    },
    resetCompanyData() {
      this.companyData = {
        company_name: null,
        has_brand_name: false,
        brand_name: null,
        header_image: null,
        company_image: null,
        primary_color: '#006859',
        secondary_color: '#B7B7B7',
        admin_name: null,
        admin_email: null,
        has_license_code: false,
        license_code: null,
        default_company_image_url: this.cdnBaseUrl + '/base/dashboard/default-logo.png',
        default_social_wall_image_url: this.cdnBaseUrl + '/base/dashboard/default-socialwall.jpg'
      };
    }
  },
  mounted: function() {
    // Set the base URL for the CDN
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    // Get the environment variables
    this.getEnvironmentVariables();
    // Reset company data
    this.resetCompanyData();
  }
}
</script>

<style>
  #fileInput,
  #fileInputHeader {
    display: none;
  }
</style>
